.Testimonial {

}

.Testimonial-cover-col {
  @include media-breakpoint-up(sm) {
    @include make-col(8);
    @include make-col-offset(2);
  }
  @include media-breakpoint-up(md) {
    @include make-col(10);
    @include make-col-offset(1);
  }
  @include media-breakpoint-up(md) {
    padding-top: 4rem;
  }
  @include media-breakpoint-up(xl) {
    @include make-col(8);
    @include make-col-offset(2);
  }
}

.Testimonial-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  @include media-breakpoint-up(sm) {
    flex-wrap: nowrap;
  }
}

.Testimonial-header-name {
  @include media-breakpoint-down(xs) {
    flex: 0 0 100%;
    order: 2;
  }
}

.Testimonial-header-title {
  color: $color-cta-primary;
  margin: 0;
  font-weight: 600;
}

.Testimonial-header-role {
  margin: .5rem 0 0;
  font-weight: 500;
}

.Testimonial-header-avatar {
  flex-shrink: 0;
  width: 6rem;
  height: 6em;
  margin-bottom: 1rem;
  background: center/cover no-repeat;
  border-radius: 50%;
  overflow: hidden;
  @include lazyloaded();
  @include media-breakpoint-up(sm) {
    margin-left: 2rem;
    width: 8rem;
    height: 8em;
  }
  @include media-breakpoint-only(md) {
    width: 6rem;
    height: 6em;
  }
}

.Testimonial-content-header {
  margin-bottom: 2rem;
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.Testimonial-content-title {
  font-weight: 600;
  color: $color-cta-primary;
  margin-bottom: .5rem;
}

.Testimonial-content-caption {
  font-weight: 600;
  margin-bottom: 0;
}
