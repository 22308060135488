.PageHeader {
  position: relative;
  width: 100%;
  color: color("white");
  background-color: color("orange-3");
}

.PageHeader-col {
  position: relative;
  z-index: 3;
  &:before {
    content: '';
    display: block;
    height: 30vw;
    min-height: 200px;
  }
  .PageHeader--image & {
    min-height: 80vh;
    &:before {
      height: 40vh;
    }
  }
  @include media-breakpoint-down(xs) {
    .PageHeader--roundImage.PageHeader--image & {
      min-height: auto;
      &:before {
        height: 100vw;
        min-height: 100vw;
      }
    }
  }
  @include media-breakpoint-up(sm) {
    @include make-col(8);
    min-height: 50vh;
    &:before {
      height: 25vh;
    }
    .PageHeader--image & {
      min-height: 80vh;
      &:before {
        height: 40vh;
      }
    }
  }
  @include media-breakpoint-up(md) {
    @include make-col(6);
    @include make-col-offset(1);
  }
  @include media-breakpoint-up(lg) {
    min-height: 60vh;
    &:before {
      height: 30vh;
    }
    .PageHeader--image & {
      min-height: 100vh;
      &:before {
        height: 50vh;
      }
    }
  }
}

.PageHeader-media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;

  .PageHeader--roundImage.PageHeader--image & {
    @include media-breakpoint-down(xs) {
      width: 100vw;
      height: 100vw;
      left: auto;
      right: 0;
      transform: translate(40%, .5rem);
    }
    @include media-breakpoint-up(sm) {
      width: 100vh;
      height: 100vh;
      min-width: 400px;
      min-height: 400px;
      left: 48%;
      top: 50%;
      transform: translate(0, -50%);
    }
    @include media-breakpoint-up(md) {
      left: 58%;
    }
    @include media-breakpoint-up(lg) {
      width: 120vh;
      height: 120vh;
      left: 48%;
    }
    @include media-breakpoint-up(xxl) {
      left: 55%;
    }
  }
}

.PageHeader-imageWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: color("blue-2");
  .PageHeader--roundImage.PageHeader--image & {
    border-radius: 50%;
    transform: translateZ(0);
    background-color: color("orange-2");
  }
}

.PageHeader-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transform: scale(1.1);
  transition: opacity 1s, transform 1.6s;
  &.lazyloaded {
    opacity: 1;
    transform: scale(1.01);
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    opacity: .3;
  }
  .PageHeader--roundImage.PageHeader--image & {
    &:after {
      opacity: .2;
    }
  }
}

.PageHeader-video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;

  &.lazyloaded {
    opacity: 1;
  }

  video {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    opacity: 0.2;
  }
}

.PageHeader-breadcrumb {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  li {
    &:not(:last-child):after {
      content: '/';
      display: inline-block;
      padding: 0 .3em;
    }
  }
}

.PageHeader-title {
  margin: 0;
  .PageHeader--image:not(.PageHeader--roundImage) & {
    text-shadow: .06em .04em 0 rgba(#fff, .35);
  }
  em {
    font-style: normal;
    color: color("orange-3");
    .PageHeader--image:not(.PageHeader--roundImage) & {
      text-shadow: .06em .04em 0 rgba(color("orange-3"), .35);
    }
  }
  .PageHeader--home & {
    // @include heading-type("c");
    @include ms-respond(font-size, 58);
    text-shadow: .06em .04em 0 rgba(#fff, .35);
  }
}

.PageHeader-content {
  padding-bottom: 2rem;
  @include media-breakpoint-up(sm) {
    padding-bottom: 10vh;
  }
}

.PageHeader-hint {
  @include heading();
  @include heading-type("i");
  margin: 2rem 0 0;
  display: flex;
  align-items: center;
  padding-left: 1em;
  &:before {
    display: inline-block;
    content: '';
    width: 0;
    height: 0;
    margin-right: .75em;
    border-left: .5em solid transparent;
    border-right: .5em solid transparent;
    border-top: .5em solid color("white");
    .PageHeader--image & {
      border-top-color: color("orange-3");
    }
  }
}

// because logo slider has arrived much than the original design and css
.PageHeader--logoSlider {
  .PageHeader-col {
    min-height: auto !important;
    &:before {
      height: 30vh;
      @include media-breakpoint-up(md) {
        height: 25vh;
      }
    }
  }
  > .container {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(lg) {
      min-height: 100vh;
    }
    > .row {
      margin-top: auto;
    }
  }
  .PageHeader-content {
    @include media-breakpoint-up(md) {
      // padding-bottom: 5vh;
    }
  }
  .PageHeader-logoSlider {
    display: block;
  }

}

.PageHeader-logoSlider {
  display: none;
  margin-top: 1rem;
  padding-bottom: 2rem;
}
