.Event {
  @include res-prop("padding-top", $site-header-total-height);
  @include sectionSpacer("margin-top", 2);
  }

.Event-mainCol {
  @include media-breakpoint-up(sm) {
    @include make-col(10);
    @include make-col-offset(1);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(8);
    @include make-col-offset(2);
  }
}

.Event-tags {
  margin-bottom: 1rem;
}

.Event-title {
  @include sectionSpacer("margin-bottom");
}

.Event-meta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  @include contentSpacer("margin-bottom");
  .Date {
    margin-right: 1rem;
  }
}

.Event-cover {
  position: relative;
  @include sectionSpacer("margin-bottom");
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: currentColor;
    opacity: .15;
  }
}

.Event-cover-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: center/cover no-repeat;
  @include lazyloaded();
}

.Event-body {
  @include sectionSpacer("margin-bottom");
  h2 {
    color: $color-cta-primary;
    font-weight: 600;
  }
  @include media-breakpoint-up(lg) {
    @include make-col(5,8);
  }
  @include media-breakpoint-up(xl) {
    @include make-col(5.5,8);
  }
}

.Event-cta {
  @include sectionSpacer("margin-bottom");
  @include media-breakpoint-up(lg) {
    text-align: right;
    @include make-col(3,8);
    order: 2;
  }
  @include media-breakpoint-up(xl) {
    @include make-col(2.5,8);
  }
}
