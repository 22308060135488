.Mask {
  // display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: $z-index-mask;
  background-color: color("orange-3");
  transform: scaleY(0);
  transform-origin: 0 100%;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    opacity: .1;
  }
  .page-transitioning-out & {
    transform-origin: 0 0;
  }
}
