.Text {
  @include section();
  position: relative;
}


.Text-container {
}

.Text-content {
  @include contentCol();
  &:before {
    content: '';
    display: block;
    border-top: 1px solid currentColor;
    opacity: .2;
    @include contentSpacer("padding-bottom");
  }
}

.Text-caption {
  @include contentSpacer("margin-bottom");
  @include media-breakpoint-up(sm) {
    margin-bottom: 0;
    @include make-col(4);
  }
  @include media-breakpoint-up(md) {
    @include make-col(4,10);
  }
}

.Text-main {
  h2 {
    color: $color-cta-primary;
  }
  @include media-breakpoint-up(sm) {
    @include make-col(8);
    margin-left: auto;
  }
  @include media-breakpoint-up(md) {
    @include make-col(6,10);
  }
}
