.NewsList {
}

.NewsList-list {
  @include sectionSpacer("margin-bottom");
}

.NewsList-item {
  a:hover {
    text-decoration: none;
  }
  &:before {
    content: '';
    display: block;
    border-top: 1px solid currentColor;
    opacity: .2;
  }
  @include contentSpacer("margin-bottom");
  @include media-breakpoint-up(sm) {
    @include make-col(6);
  }
  @include media-breakpoint-up(md) {
    @include make-col(4);
  }
}

.NewsList-item-meta {
  display: flex;
  margin: 1rem 0;
  align-items: center;
  justify-content: space-between;
  > * {
    margin: 0;
  }
}

.NewsList-item-category {
  color: color("orange-3");
}

.NewsList-item-date {
  opacity: .5;
}

.NewsList-item-media {
  display: block;
  margin-bottom: 1rem;
  .desktop & {
    padding-bottom: 1rem;
    margin-bottom: 0;
  }
}

.NewsList-item-figure {
  position: relative;
  &:before {
    content: '';
    display: block;
    height: 0;
    padding-bottom: 9/16 * 100%;
    background: currentColor;
    opacity: .15;
  }
}

.NewsList-item-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: center/cover no-repeat;
  @include lazyloaded();
}

.NewsList-item-title {
  margin-bottom: 1rem;
  transition: color .2s;
  a:hover &,
  .hover & {
    color: $color-cta-primary;
  }
}
