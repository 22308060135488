.TestimonialList {
  @include section();
  overflow: hidden;
  .swiper-container {
    overflow:visible;
    @include make-row();
  }
  .swiper-scrollbar {
    @include res-prop(("margin-left", "margin-right"), $grid-gutter-widths, .5);
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }
  .swiper-pagination {
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}

.TestimonialList-main {
  @include contentCol();
}

.TestimonialList-title {
  @include contentSpacer("margin-bottom");
}

.TestimonialList-slide {
  height: auto;
  display: flex;
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col(6);
  }
}

.TestimonialList-item {
  width: 100%;
  background-color: $color-background-gray;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  min-height: 60vw;
  color: $color-text;
  .bg-gray & {
    background-color: $color-background;
  }
  @include media-breakpoint-up(sm) {
    min-height: 30vw;
    padding: 2rem;
  }
  @include media-breakpoint-up(md) {
    min-height: 25vw;
    padding: 2.5rem;
  }
  @include media-breakpoint-up(lg) {
    padding: 3.5rem;
  }
}

.TestimonialList-item-profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.TestimonialList-item-title {
  color: $color-cta-primary;
  margin-bottom: .5rem;
}

.TestimonialList-item-avatar {
  flex-shrink: 0;
  margin-left: 1rem;
  width: 5.5rem;
  height: 5.5rem;
  background: center/cover no-repeat;
  border-radius: 50%;
  overflow: hidden;
  @include lazyloaded();
  @include media-breakpoint-down(xs) {
    width: 4rem;
    height: 4rem;
  }
}

.TestimonialList-item-description {
  margin-bottom: 2rem;
}

.TestimonialList-item-cta {
  font-weight: bold;
  text-decoration: underline;
  margin-top: auto;
  align-self: flex-end;
}
