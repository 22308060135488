.Featured {
  @include section();
  .swiper-slide {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: currentColor;
      opacity: .05;
    }
  }
  .swiper-pagination {
    display: none;
    &.swiper-pagination-bullets {
      display: block;
    }
  }
}

.Featured-title {
  text-align: center;
  margin: 0 auto;
  max-width: 12em;
  @include sectionSpacer("margin-bottom");
}

.Featured-mainCol {
  @include contentCol("lg");
  &:not(:first-child) {
    @include sectionSpacer("margin-top");
  }
  &:nth-child(odd) .row {
    flex-direction: row-reverse;
  }
}

.Featured-imagesCol {
  margin-bottom: 2rem;
  @include media-breakpoint-up(sm) {
    margin-bottom: 0;
    @include make-col(6);
    align-self: center;
  }
}

.Featured-contentCol {
  @include media-breakpoint-up(sm) {
    @include make-col(6);
    align-self: center;
  }
}

.Featured-image {
  height: 0;
  padding-bottom: 100%;
  background: center/cover no-repeat;
  position: relative;
  @include lazyloaded();
}

.Featured-content {
  h1, h2, h3, h4 {
    color: $color-cta-primary;
  }
}
