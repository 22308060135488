.Tabs {

}

.Tabs-nav {
  @include sectionSpacer("padding-top");
  &:not(:first-child) {
    padding-top: 0;
  }
  transition: background-color .2s;
}


.Tabs-panels {
  position: relative;
  overflow: hidden;
}

.Tabs-panel {
  transition: opacity .2s;
  &:not(.is-active) {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }
}
