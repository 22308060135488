.fancybox-bg {
  background-color: #fff;
}
.fancybox--overlay {
  .fancybox-slide--html {
    padding: 0;
  }
  .fancybox-content {
    padding: 0;
  }
}

.fancybox-toolbar {
  @include make-container();
  @include res-prop("padding-top", $site-header-paddings);
  display: flex;
  justify-content: flex-end;
}

.fancybox-button--close {
  @extend .Overlay-back;
}

.fancybox--media {
  .fancybox-bg {
    background-color: $color-text;
  }
  &.fancybox-is-open .fancybox-bg {
    opacity: 1;
  }
  .fancybox-slide {
    @include res-prop("padding", $grid-container-paddings);
    @include res-prop("padding-top", $site-header-total-height);
  }
}
