.Cards {
  @include section();
}

.Cards-header {
}

.Cards-title {
  max-width: 12em;
  color: $color-cta-primary;
  @include sectionSpacer("margin-bottom");
}

.Cards-label {
}

.Cards-main {
  @include contentCol();
}

.Cards-col {
  display: flex;
  @include grid-spacer("m", "t");
  &:first-child {
    margin-top: 0;
  }
  @include media-breakpoint-up(sm) {
    @include make-col(6);
    &:nth-child(2) {
      margin-top: 0;
    }
  }
}

.Cards-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 50vw;
  color: $color-text;
  background-color: $color-background-gray;
  padding: 1.5rem;
  .bg-gray & {
    background-color: $color-background;
  }
  @include media-breakpoint-up(sm) {
    padding: 2rem;
    min-height: 45vw;
  }
  @include media-breakpoint-up(md) {
    min-height: 30vw;
    padding: 2.5rem;
  }
  @include media-breakpoint-up(lg) {
    padding: 3.5rem;
  }
}

.Card-content {
  h1, h2, h3, h4, h5, h6 {
    color: $color-cta-primary;
    margin-bottom: 1rem;
  }
}

.Card-ctaList {
  @include heading();
  @include heading-type("h");
  font-weight: 400;
  margin: auto 0 0 0;
  padding: 1rem 0 0 0;
  list-style: none;
}

.Card-ctaItem {
  padding-top: 1rem;
}

.Card-ctaLink {
  text-decoration: underline;
}

