.ToggleNav {
  display: flex;
  font-size: calc(1rem - 2px);
  font-weight: 500;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  position: relative;
  width: 100%;
  max-width: rem(400px);
  margin-left: auto;
  margin-right: auto;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid currentColor;
    opacity: .4;
    border-radius: rem(60px);
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    bottom: 0;
    background-color: $color-cta-primary;
    border-radius: rem(60px);
    transition: transform .3s;
  }
  &[data-selected-tab="1"] {
    &:after {
      transform: translateX(100%);
    }
  }
}

.ToggleNav-wrapper {
  position: relative;
  display: flex;
  flex: 0 0 100%;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: currentColor;
    opacity: 0;
    transition: opacity .2s;
    border-radius: rem(60px);
  }
  &:hover:before {
    opacity: .1;
  }
}

.ToggleNav-link {
  position: relative;
  z-index: 2;
  height: rem(60px);
  display: flex;
  align-items: center;
  flex: 0 0 50%;
  justify-content: center;
  text-decoration: none !important;
  &[aria-selected="true"] {
    color: $color-text-inverted;
  }
  .Prose & {
    text-decoration: none !important;
  }
}
