.Date {
  display: flex;
  visibility: hidden;
  flex-wrap: wrap;
  time {
    display: none;
  }
  &.is-ready {
    visibility: visible;
  }
}

.Date-wrapper {
  display: flex;
}

.Date-time {
  display: flex;
}

.Date-day {
  opacity: .5;
}

.Date-day,
.Date-time,
.Date-select-label {
  @include heading();
  @include heading-type("i");
  margin-bottom: 1rem;
}

.Date-day,
.Date-time {
  padding-right: 1em;
}

.Date-select {
  position: relative;
  overflow: hidden;
}

.Date-select-label {
  display: flex;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    right: 0;
    border-bottom: 1px solid currentColor;
  }
  &:after {
    content: '';
    display: block;
    width: rem(13px);
    height: rem(13px);
    margin-left: 1em;
    $style : #{"fill: none; stroke: " + $color-text + "; stroke-width: 1.2;"};
    background-image: url(svg("clock", $style));
    background-size: 100%;
  }
}

.Date-select-select {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
