.PageHeaderSimple {
  @include res-prop("padding-top", $site-header-total-height);
  @include sectionSpacer("margin-top", 2);
  @include sectionSpacer("margin-bottom", 2);
}

.PageHeaderSimple-label {
  margin: 0;
}

.PageHeaderSimple-title {
  font-weight: 600;
  margin: 0;
  color: color("orange-3");
  text-shadow: .06em .04em 0 rgba(color("orange-3"), .35);
}

.PageHeaderSimple-description {
  font-weight: 400;
  margin: 1rem 0 0;
  max-width: 30em;
  line-height: 1.5;
}
