.LogoGrid {
  @include section();
  * {
    background-color: inherit;
  }
}

.LogoGrid-content {
  @include contentCol();
}

.LogoGrid-label {
  @include contentSpacer("margin-bottom");
}

.LogoGrid-grid {
  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.LogoGrid-slider {
  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.LogoGrid-item {
  @include make-col-ready();
  @include make-col(3);
  @include contentSpacer("margin-top");
  &:nth-child(-n+4) {
    margin-top: 0;
  }
  @include media-breakpoint-up(lg) {
    @include make-col(12/5);
    &:nth-child(5) {
      margin-top: 0;
    }
  }
  @include media-breakpoint-up(xl) {
    @include make-col(2);
    &:nth-child(6) {
      margin-top: 0;
    }
  }
}

.LogoGrid-figure {
  padding-bottom: 50%;
  position: relative;
  mix-blend-mode: multiply;
  opacity: .6;
  background-color: #fff;
}

.LogoGrid-image {
  position: absolute;
  top: 0;
  left: 10%;
  right: 10%;
  bottom: 0;
  background: center/contain no-repeat;
  filter: grayscale(1) contrast(1.1);
  background-color: #fff;
  @include lazyloaded();
}
