.MarkupPagerNav {
  @include sectionSpacer("margin-bottom");
  @include sectionSpacer("margin-top");
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  font-weight: 500;

  li:not(:last-child) {
    margin-right: .5rem;
  }

  li a,
  .MarkupPagerNavSeparator {
    display: flex;
    width: 2.6rem;
    height: 2.6rem;
    position: relative;
    align-items: center;
    justify-content: center;
  }

  li a span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  li a:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border-radius: 50%;
    background-color: currentColor;
    opacity: 0;
    transition: opacity .2s;
  }

  li a:hover:before {
    opacity: .1;
  }

  li.MarkupPagerNavNext,
  li.MarkupPagerNavPrevious {
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid $color-cta-primary;
      border-radius: 50%;
      pointer-events: none;
    }
    a:before {
      background-color: $color-cta-primary;
    }
    a:hover:before {
      opacity: 1;
    }
    a:after {
      content: '';
      display: block;
      width: 1rem;
      height: 1rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      @extend %dark-arrow-bg;
    }
    span {
      display: none;
    }
  }

  .MarkupPagerNavOn a:before {
    opacity: .3 !important;
  }
}

.MarkupPagerNavPrevious {
  a {
    transform: rotate(180deg);
  }
}

@include media-breakpoint-down(xs) {
  .MarkupPagerNav .MarkupPagerNavOn a:before {
    opacity: .15 !important;
  }
  .MarkupPagerNav li {
    display: none !important;
    &.MarkupPagerNavPrevious,
    &.MarkupPagerNavOn,
    &.MarkupPagerNavNext {
      display: block !important;
    }
  }
}

