@mixin Images--2Col() {
  @include make-col(6);
  &:nth-child(2) {
    margin-top: 0;
  }
}

@mixin Images--3Col() {
  @include make-col(4);
  &:nth-child(2),
  &:nth-child(3) {
    margin-top: 0;
  }
}

.Images {
  @include section();
}

.Images-row {
  align-items: flex-start;
}

.Images-content {
  @include contentCol();
}

.Images-col {
  @include res-prop("margin-top", $grid-gutter-widths);
  &:first-child {
    margin-top: 0;
  }

  .Images--2ColXs & {
    @include Images--2Col();
  }

  .Images--2ColSm & {
    @include media-breakpoint-up(sm) {
      @include Images--2Col();
    }
  }

  .Images--3ColSm & {
    @include media-breakpoint-up(sm) {
      @include Images--3Col();
    }
  }

  .Images--3ColMd & {
    @include media-breakpoint-up(md) {
      @include Images--3Col();
    }
  }

  .Images--3ColLg & {
    @include media-breakpoint-up(lg) {
      @include Images--3Col();
    }
  }

}

.Images-imageWrapper {
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    @include res-prop(("left", "right"), $grid-gutter-widths, .5);
    background-color: currentColor;
    opacity: .05;
  }
}

.Images-image {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  @include lazyloaded();
}


