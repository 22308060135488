.LogoSlider {
  overflow: hidden;
  position: relative;
  z-index: 4;
  mix-blend-mode: screen;
  .LogoGrid & {
    background-color: #fff;
    mix-blend-mode: multiply;
  }
  // .swiper-container {
    // overflow: visible;
  // }
  .swiper-slide {
    width: 33.333%;
    @include media-breakpoint-up(sm) {
      width: 25%;
    }
    @include media-breakpoint-up(md) {
      width: 20%;
    }
    @include media-breakpoint-up(xl) {
      width: 100/6 * 1%;
    }
  }
  // prevent redraw
  .swiper-scrollbar {
    height: 3px;
    @include res-prop("margin-top", $grid-gutter-widths);
  }
}

.LogoSlider-container {
}

.LogoSlider-col {
  @include contentCol();
}

.LogoSlider-figure {
  padding-bottom: 40%;
  position: relative;
  @include media-breakpoint-up(md) {
    padding-bottom: 35%;
  }
}

.LogoSlider-image {
  position: absolute;
  top: 0;
  left: 10%;
  right: 10%;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  filter: invert(1) grayscale(1) contrast(1.1);
  background-color: #fff;
  @include lazyloaded();
  .LogoGrid & {
    filter: grayscale(1) contrast(1.1);
    opacity: .6;
  }
}
