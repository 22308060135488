.EventList {
  transition: transform .6s, opacity 0.6s;
  &.reveal {
    opacity: 0;
    transform: translateY(2rem);
    transition: none;
  }
  .Date:not(.day-changed) .Date-day {
    display: none;
  }
}

.EventList-list {
  @include sectionSpacer("margin-bottom");
}

.EventList-date {
  border-top: 1px solid rgba($color-text, .15);
  padding: 1.5rem 0;
  display: flex;
  text-transform: uppercase;
  @include heading-type("e");
  line-height: 1;
  font-weight: 600;
  > *:not(:last-child) {
    margin-right: .3em;
  }
  @include media-breakpoint-up(md) {
    flex-wrap: wrap;
    border-top: none;
    @include make-col-ready();
    @include make-col(3);
    padding: 2rem 0;
  }
}

.EventList-events {
  @include media-breakpoint-up(md) {
    @include make-col-ready();
    @include make-col(9);
  }
}

.EventList-date-weekday {
  font-weight: 400;
  @include media-breakpoint-up(md) {
    flex: 0 0 100%;
  }
}

.EventList-date-year {
  font-weight: 400;
  opacity: 0.5;
  @include media-breakpoint-up(md) {
    flex: 0 0 100%;
  }
}

.EventList-dayList {
  align-items: flex-start;
  &:before {
    content: '';
    display: block;
    width: 100%;
    @include grid-spacer("m", "x", .5);
    border-top: 1px solid rgba($color-text, .15);
    display: none;
  }
  @include media-breakpoint-up(md) {
    @include make-row();
    &:before {
      display: block;
    }
  }
}

.EventList-event {
  border-top: 1px solid rgba($color-text, .15);
  padding-top: 1rem;
  padding-bottom: 2rem;
  @include media-breakpoint-up(sm) {
    padding-top: 2rem;
    @include make-row();
  }
  @include media-breakpoint-up(md) {
    border-top: none;
    &:not(:first-child) {
      &:before {
        content: '';
        display: block;
        width: 100%;
        border-top: 1px solid rgba($color-text, .15);
        position: relative;
        top: -2rem;
        @include grid-spacer("m", "x", .5);
      }
    }
  }
}

.EventList-event-title {
  padding-top: .5rem;
  transition: color .2s;
  a:hover &,
  .hover & {
    color: $color-cta-primary;
  }
}

.EventList-event-link {
  &:hover {
    text-decoration: none;
  }
}

.EventList-event-figure {
  margin-bottom: 1rem;
  position: relative;
  &:before {
    content: '';
    display: block;
    padding-bottom: 9/16 * 100%;
    background-color: currentColor;
    opacity: .15;
  }
  @include media-breakpoint-up(sm) {
    margin-bottom: 0;
  }
}

.EventList-event-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: center/cover no-repeat;
  @include lazyloaded();
}

.EventList-dayList:not(.no-sticky) .EventList-date {
  top: 0;
  z-index: 10;
  position: sticky;
  background-color: $color-background;
}

.EventList-event-media {
  @include media-breakpoint-up(sm) {
    @include make-col-ready();
    @include make-col(5);
    @include make-col-offset(1);
    order: 2;
  }
}

.EventList-event-content {
  @include media-breakpoint-up(sm) {
    @include make-col-ready();
    @include make-col(6);
  }
}

.EventList-empty {
  @include sectionSpacer("margin-bottom", 4);
}

