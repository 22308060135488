:root {
  --vh: 1vh;
}

html {
  @include fluid-sizing("font-size", (1440px: 16px, 1920px: 18px, 2400px: 22px));
  @include media-breakpoint-down(xs) {
    font-size: 14px;
  }
  @include font-smoothing();
  // -webkit-tap-highlight-color: rgba(color("orange-3"), .2);
  // padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);

  &.is-loading {
    cursor: wait !important;
    * {
      pointer-events: none;
    }
  }
}

body {
  overscroll-behavior-y: none;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
}

a[href^="tel:"] {
  text-decoration: none !important;
}

.js-focus-visible :focus:not(.focus-visible),
.js-focus-visible button:focus:not(.focus-visible) {
  outline: none;
}

.focus-visible {
  outline: 2px solid color("orange-3") !important;
  outline-offset: 4px !important;
  .show-menu .SiteHeader-overlay & {
    outline-color: #fff !important;
  }
}

input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
textarea,
select,
.bootstrap-select .dropdown-toggle {
  &.focus-visible {
    outline: none !important;
  }
}

label {
  &:not(.custom-control-label) {
    @include label-heading();
  }
  &.custom-file-label,
  &.form-check-label,
  .InputfieldRadiosStacked &,
  .InputfieldCheckbox &:not(.InputfieldHeader) {
    @include label();
  }
}
