.dropdown-toggle {
  // reset button default style
  padding: 0;
  background-color: transparent;
  border: none;
  color: inherit;
  font-weight: inherit;
  &:focus {
    outline: none;
  }

  // to better position caret element:
  display: flex !important;
  align-items: center;

  .dropdown.show &:after {
    @include caret-up();
  }
}

.dropdown-item {
  font-weight: inherit;
  // &:focus {
  //   outline: none;
  // }
}

.dropdown-menu {
  font-size: inherit;
  box-shadow: $box-shadow;
  // display: block;
  // opacity: 0;
  // pointer-events: none;
  // transform: translateY(10px);
  // transition: none;
  .dropdown--flat & {
    padding: 0;
    border: none;
    border-radius: 0;
  }
  &.show {
    // opacity: 1;
    // pointer-events: auto;
    // transform: translateY(0) !important;
    // top: auto !important;
    // left: auto !important;
    // transition: opacity .2s, transform .3s;
  }
}
