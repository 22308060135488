.Cta {
  @include section();
  position: relative;
}

.Cta-content {
  @include contentCol();
}

.Cta-label {
  @include contentSpacer("margin-bottom");
}
