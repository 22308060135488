.Gallery {
  @include section();
  overflow: hidden;
  .swiper-container {
    overflow: visible;
  }
  .swiper-slide {
    width: auto;
    &:not(:last-child) {
      @include res-prop("padding-right", $grid-gutter-widths, 0.5);
    }
  }
}

.Gallery-col {
  @include contentCol();
}

.Gallery-imageWrapper {
  overflow: hidden;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: currentColor;
    opacity: .05;
  }
}

.Gallery-image {
  position: relative;
  height: 75vw;
  max-height: 50vh;
  @include lazyloaded();
}
