.bg-gray {
  background-color: color("gray-95");
}

.bg-blue-1 {
  background-color: color("blue-1");
  color: $color-text-inverted;
}

.bg-blue-2 {
  background-color: color("blue-2");
  color: $color-text-inverted;
}

.bg-orange {
  background-color: color("orange-3");
  color: $color-text-inverted;
}
