.custom-control-label {
  &:after {
    @include media-breakpoint-up(sm) {
      // top: 5px;
      // left: -27px;
      background-size: 10px 10px;
      background-position: center;
      // .InputfieldCheckboxesStacked & {
      //   top: 4px;
      // }
    }
  }
}

.custom-control-input.focus-visible:focus~.custom-control-label::before {
  @extend .focus-visible;
}
