.Prose {

  h1, h2, h3, h4, h5, h6 {
    // @include heading(null, true);
    // margin-bottom: calc(1rem + 0.4em);
  }

  p, table, ul, ol, figure, img, iframe {
    margin-bottom: 1rem;
    @include media-breakpoint-up(sm) {
      margin-bottom: 1rem;
    }
    + h1,
    + h2,
    + h3,
    + h4,
    + h5 {
      @include contentSpacer("margin-top");
    }
  }

  > *:first-child,
  // for ckeditor
  .cke_widget_wrapper:first-child > *:first-child,
  span:first-child + .cke_widget_wrapper > *:first-child {
    margin-top: 0 !important;
  }

  > *:last-child {
    margin-bottom: 0 !important;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto !important;
    // &.img-w100 {
    //   width: 100% !important;
    // }
  }

  a {
    text-decoration: underline;
  }

  p {
    opacity: .9;
  }

  figcaption {
    @include media-breakpoint-up(sm) {
      width: 50%;
    }
  }

  > iframe {
    margin: 1.5rem 0;
  }


  blockquote {
    p {
      @include heading();
      @include heading-type("f");
      color: $color-cta-primary;
      font-style: italic;
    }
  }

}
