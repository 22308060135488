.FiltersNav {
  @include contentSpacer("margin-bottom");
}

.FiltersNav-select {
  position: relative;
  select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.FiltersNav-select-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:after {
    content: '';
    display: inline-block;
    width: 2rem;
    height: 2rem;
    border: 1px solid color("orange-3");
    border-radius: 50%;
    margin-left: 1rem;
    background-size: 1rem;
    background-repeat: no-repeat;
    background-position: center;
    @extend %dark-arrow-bg;
    transform: rotate(90deg);
  }
}

.FiltersNav-list {
  font-weight: 500;
  @include media-breakpoint-down(xs) {
    display: none;
  }
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
  }
  li {
    &:not(:first-child) {
      margin-left: 1.5em;
    }
  }
  a {
    display: inline-block;
    padding: .2em 0;
    &:hover {
      text-decoration: none;
      span {
        text-decoration: underline;
      }
    }
    &.is-active {
      position: relative;
      color: $color-cta-primary;
      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: -0.75em;
        right: -0.75em;
        bottom: 0;
        border: 1px solid currentColor;
        border-radius: 50px;
      }
    }
  }
  sup {
    padding-left: 0.3em;
  }
}
