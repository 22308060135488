.People {

}

.People-cover-col {
  @include media-breakpoint-up(sm) {
    @include make-col(8);
    @include make-col-offset(2);
  }
  // @include media-breakpoint-up(md) {
  //   @include make-col(10);
  //   @include make-col-offset(1);
  // }
}

.People-header {
  text-align: center;
  margin-bottom: 1rem;
}

.People-header-title {
  color: $color-cta-primary;
  margin-bottom: 0;
  font-weight: 600;
}

.People-header-caption {
  margin: .5rem 0 0;
}

.People-avatar {
  display: flex;
  position: relative;
  z-index: 0;
  background: center/cover no-repeat;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 1rem;
  width: 70%;
  @include lazyloaded();
  &:before {
    content: '';
    height: 0;
    padding-bottom: 100%;
  }
}

.People-contacts {
  display: flex;
  border-top: 1px solid $color-cta-primary;
  padding-top: .25rem;
  margin-bottom: 2rem;
  order: -1;
  span {
    margin-right: auto;
  }
  a {
    margin-left: 1rem;
  }
}

.People-content-header {
  margin-bottom: 2rem;
  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.People-content-title {
  font-weight: 600;
  color: $color-cta-primary;
  margin-bottom: .5rem;
}

.People-content-caption {
  font-weight: 600;
  margin-bottom: 0;
}

