.SearchForm {
  position: relative;
  z-index: 2;
  color: #fff;
  .Search & {
    color: $color-text;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-image: linear-gradient(currentColor,currentColor);
    background-size: 100% 1px;
    background-repeat: repeat-x;
    background-position: 0 100%;
    opacity: .3;
  }
}

.SearchForm-input {
  width: 100%;
  appearance: none;
  border: none;
  background: none;
  color: currentColor;
  font-size: 1.5rem;
  padding: .5em 2rem .5em 0;
  font-weight: 500;
  background-image: linear-gradient(currentColor,currentColor);
  background-size: 0 1px;
  background-repeat: no-repeat;
  background-position: 0 100%;
  transition: background-size .6s;
  transition-timing-function: cubic-bezier(.77,0,.175,1);
  color: currentColor;
  &:focus {
    background-size: 100% 1px;
  }
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: currentColor;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: currentColor;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: currentColor;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: currentColor;
  }
}

.SearchForm-button {
  position: absolute;
  right: 0;
  top: 50%;
  display: block;
  transform: translateY(-50%);
  width: 2rem;
  height: 2rem;
  border: 1px solid color("orange-3");
  border-radius: 50%;
  appearance: none;
  background-color: transparent;
  background-size: 1rem;
  background-repeat: no-repeat;
  background-position: center;
  transition: transform .3s, background-color .2s;
  @extend %light-arrow-bg;
  .Search & {
    @extend %dark-arrow-bg;
  }
  .desktop &:hover {
    background-color: color("orange-3");
  }
}
