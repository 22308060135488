.swiper-container-horizontal>.swiper-scrollbar {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  @include res-prop("margin-top", $grid-gutter-widths);
}

.swiper-scrollbar {
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: currentColor;
    border-radius: 10px;
    opacity: .10;
  }
}

.swiper-scrollbar-drag {
  background-color: currentColor;
  opacity: .5;
}

.swiper-pagination-numbered.swiper-pagination-bullets {
  position: static;
  margin-top: 1rem;
  .swiper-pagination-bullet {
    background: none;
    height: 2em;
    width: 2em;
    display: inline-flex;
    justify-content: center;
    position: relative;
    line-height: 2;
    margin: 0 .2em;
    opacity: 1;
    &:before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid $color-cta-primary;
      border-radius: 50%;
      opacity: 0;
      transition: opacity .3s, transform .3s;
      transform: scale(1.1);
    }
    &.swiper-pagination-bullet-active:before {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  position: static;
  margin-top: 1rem;
}

.swiper-pagination-bullet {
  background-color: currentColor;
}
