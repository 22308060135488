@function svg($svg-name, $svg-style: "", $encode: true) {
  $svg : map_get($svg, $svg_name);
  $svg : str-replace($svg, "{$svg-style}", $svg-style);
  @if($encode == true) {
    $svg : svg-encode($svg);
  }
  @return $svg;
}

%light-arrow-bg {
  $style : "fill: none; stroke: #fff; stroke-width: 1.2;";
  background-image: url(svg("arrow", $style));
}

%dark-arrow-bg {
  $style : #{"fill: none; stroke: " + $color-text + "; stroke-width: 1.2;"};
  background-image: url(svg("arrow", $style));
}

%light-plus-bg {
  $style : "fill: none; stroke: #fff; stroke-width: 1.2;";
  background-image: url(svg("plus", $style));
}

%dark-plus-bg {
  $style : #{"fill: none; stroke: " + $color-text + "; stroke-width: 1.2;"};
  background-image: url(svg("plus", $style));
}

@mixin contentCol($from: "md") {
  @include media-breakpoint-up($from) {
    @include make-col(10);
    margin-left: auto;
    margin-right: auto;
  }
}

@function join-map($map-source, $map-resolve) {
  @each $key, $value in $map-source {
    @if (map-has-key($map-resolve, $key)) {
      $map-source: map-remove($map-source, $key);
      $key-resolved: map_get($map-resolve, $key);
      $map-source: map-merge(($key-resolved: $value), $map-source);
    }
  }
  @return $map-source;
}

// use poly-fluid-sizing with a map resolved against grid-breakpoints
@mixin fluid-sizing($property, $map) {
  @include poly-fluid-sizing($property, join-map($map, $grid-breakpoints));
}

@mixin heading($link: false) {
  margin-bottom: $headings-margin-bottom;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: currentColor;

  // @if $link==true {
  //   $line-height: 2px;
  //   $line-position-bottom: 95%;
  //   a,
  //   a & {
  //     color: inherit !important;
  //     text-decoration: none !important;
  //     @include text-shadow();
  //     background-image: linear-gradient($color-cta-primary, $color-cta-primary), linear-gradient($color-lines, $color-lines);
  //     background-position: 0% $line-position-bottom, 0% $line-position-bottom;
  //     background-repeat: no-repeat;
  //     background-size: 0 $line-height, 100% $line-height;
  //     transition: color .4s, background-size .4s;
  //     transition-timing-function: cubic-bezier("easeInOutQuart");
  //   }
  //   a:hover &,
  //   a:hover,
  //   .hover & a {
  //     background-size: 100% $line-height, 100% $line-height;
  //   }
  // }
}

@mixin heading-type($type) {

  $types: (
    a: 84,
    b: 71,
    c: 62,
    d: 50,
    e: 38,
    f: 30,
    g: 24,
    h: 14
  );

  @if map-has-key($types, $type) {
    letter-spacing: -0.02em;
    @include ms-respond(font-size, map-get($types, $type));
  }

  @if $type == "i" {
    font-size: calc(1rem - 2px);
    letter-spacing: 0.01em;
    text-transform: uppercase;
  }

  @if $type == "c" { // display-3
    font-weight: 600;
  }

  @if $type == "e" { // h2
    font-weight: 600;
  }

  @if $type == "g" { // h4
    line-height: 1.4;
  }

  @if $type == "g" { // h4
    line-height: 1.4;
  }

  @if $type == "h" { // h5
    line-height: 1.4;
  }
}

@mixin if-dark-background {
  .bg-blue-1 &,
  .bg-blue-2 &,
  .bg-orange & {
    @content;
  }
}


@mixin contentSpacer($property, $factor: 1) {
  #{$property} : 1.5rem * $factor;
  @include res-prop($property, $grid-gutter-widths, (sm: $factor * 1.4));
}

@mixin sectionSpacer($property, $factor: 1, $factor-xs: 1) {
  #{$property} : 3rem * $factor-xs;
  @include res-prop($property, $grid-gutter-widths, (sm: $factor * 2.6));
}

@mixin section() {
  @include sectionSpacer("padding-top");
  @include sectionSpacer("padding-bottom");
  .PageHeader--roundImage + & {
    @include sectionSpacer("padding-top", 1.5, 1);
  }
  .Main > &:first-child {
    @include sectionSpacer("padding-top", 2);
    @include res-prop("border-top-width", $site-header-total-height);
    border-top-style: solid;
    border-top-color: transparent;
  }
}

@mixin lazyloaded($transition: #{opacity .6s}) {
  transition: $transition;
  opacity: 0;
  &.lazyloaded {
    opacity: 1;
  }
}

@mixin lazyloadedZoom($transition: #{transform 1.6s, opacity .6s}) {
  opacity: 0;
  transition: $transition;
  transform: scale(1.1);
  &.lazyloaded {
    opacity: 1;
    transform: scale(1);
  }
}

@mixin font-size-small() {
  font-size: $small-font-size;
  line-height: $line-height-sm;
}

@mixin label() {
  color: $color-text-muted;
  font-size: $input-font-size;
  font-weight: 400;
}

@mixin label-heading() {
  color: $color-text-muted;
  font-weight: 600;
  font-size: calc(1rem - 1px);
}

// @mixin label() {
//   color: $color-text-muted;
//   font-size: $input-font-size;
//   text-transform: none;
//   letter-spacing: 0;
//   @include font-smoothing();
// }

// @mixin reveal($wait-images: false) {
//   opacity: 0;
//   transform: translateY(2rem);
//   transition: transform 1.2s cubic-bezier("easeOutCubic"), opacity 1.2s;
//   @if ($wait-images == true) {
//     &.is-shown.is-loaded {
//       opacity: 1;
//       transform: translateY(0);
//     }
//   } @else {
//     &.is-shown {
//       opacity: 1;
//       transform: translateY(0);
//     }
//   }
// }

// @mixin remove-reveal() {
//   opacity: 1 !important;
//   transform: translateY(0) !important;
// }

// @mixin revealStagger($items) {
//   $delay : .1s;
//   @for $i from 1 through $items {
//     &:nth-child(#{$items}n+#{$i}) {
//       transition-delay: #{($delay * $i) - .1s};
//     }
//   }
// }

// /* overwrite bootstrap caret */

@mixin caret-down() {
}

@mixin caret-up() {
  transform: rotate(180deg);
}

@mixin caret-right() {
  transform: rotate(90deg);
}

@mixin caret-left() {
  transform: rotate(-90deg);
}

@mixin caret($direction: down) {
  @if $enable-caret {
    &::after {
      flex-shrink: 0;
      display: inline-block;
      margin-left: $caret-spacing;
      position: relative;
      top: 0.1em;
      // vertical-align: $caret-vertical-align;
      content: "";
      width: 14px;
      height: 8px;
      background: url(svg("chevron-down", "fill: none; stroke: #{$color-text}; stroke-width: 1.1"));
      @if $direction == down {
        // @include caret-down();
      } @else if $direction == up {
        @include caret-up();
      } @else if $direction == right {
        @include caret-right();
      }
    }

    @if $direction == left {
      &::after {
        display: none;
      }

      &::before {
        flex-shrink: 0;
        display: inline-block;
        margin-right: $caret-spacing;
        // vertical-align: $caret-vertical-align;
        content: "";
        width: 14px;
        height: 8px;
        background: url(svg("chevron-down", "fill: none; stroke: #{$color-text}; stroke-width: 1.1"));
        @include caret-left();
      }
    }

    &:empty::after {
      margin-left: 0;
    }
  }
}

