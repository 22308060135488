.Account {
  @include sectionSpacer("margin-top");
  @include sectionSpacer("margin-bottom");
  @include res-prop("border-top-width", $site-header-total-height);
  border-top-style: solid;
  border-top-color: transparent;
  h2 {
    color: $color-cta-primary;
  }
  .g-recaptcha,
  .InputfieldRadios,
  .InputfieldCheckbox {
    margin-bottom: $form-group-margin-bottom;
  }
  .InputfieldContent {
    label {
      margin-bottom: 0;
    }
  }
  .InputfieldFieldset > .InputfieldHeader:first-child {
    color: $color-cta-primary;
  }
  .InputfieldRadiosStacked {
    margin-bottom: 0;
  }
  .LoginRegisterLinks {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin-bottom: .25rem;
    }
    a {
      font-size: calc(1rem - 2px);
      text-decoration: underline;
    }
  }
  .InputfieldFieldset {
    transition: all .3s;
    opacity: 1;
    [data-prev] {
      display: inline-block;
      text-decoration: underline;
      cursor: pointer;
      margin-left: 2rem;
    }
    &.is-hidden {
      position: absolute;
      pointer-events: none;
      transition: none;
      opacity: 0;
    }
    &[data-index="1"] {
      [data-prev] {
        display: none;
      }
    }
  }
}

.Account--login {
  .LoginRegisterLinks {
    margin-top: 2rem;
  }
  .LoginRegisterLinksRegister {
    display: none;
  }
}

.Account-caption {
  @include contentSpacer("margin-bottom");
}

.Account-content {
  @include contentCol();
}

.Account-col {
  @include contentSpacer("margin-bottom");
  .Account:not(.Account--login) & {
    &:first-child {
      @include media-breakpoint-up(sm) {
        @include make-col(4);
      }
      @include media-breakpoint-up(md) {
        @include make-col(4,10);
      }
    }
    &:last-child {
      @include media-breakpoint-up(sm) {
        @include make-col(8);
        margin-left: auto;
      }
      @include media-breakpoint-up(md) {
        @include make-col(6,10);
      }
    }
  }
  .Account.Account--login & {
    @include media-breakpoint-up(sm) {
      @include make-col(6);
      display: flex;
    }
  }
}

.Account-panel {
  width: 100%;
  background: $color-background-gray;
  padding: 2rem;
  @include contentSpacer("margin-bottom");
}
