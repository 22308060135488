.News {
  @include res-prop("padding-top", $site-header-total-height);
  @include sectionSpacer("margin-top", 2);
}

.News-mainCol {
  @include media-breakpoint-up(sm) {
    @include make-col(10);
    @include make-col-offset(1);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(8);
    @include make-col-offset(2);
  }
}

.News-label {
  color: $color-cta-primary;
}

.News-title {
  @include sectionSpacer("margin-bottom");
}

.News-meta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @include contentSpacer("margin-bottom");
}

.News-date {
  @include heading();
  @include heading-type("i");
  font-weight: 500;
  opacity: 0.5;
  margin-right: 2rem;
}

.News-cover {
  position: relative;
  @include sectionSpacer("margin-bottom");
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: currentColor;
    opacity: .15;
  }
}

.News-cover-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: center/cover no-repeat;
  @include lazyloaded();
}

.News-body {
  @include sectionSpacer("margin-bottom");
  h2 {
    color: $color-cta-primary;
    font-weight: 600;
  }
  @include media-breakpoint-up(lg) {
    @include make-col(6,8);
  }
}

.News-next {
  @include sectionSpacer("padding-top");
  @include sectionSpacer("padding-bottom");
  background-color: $color-background-blue-1;
  color: $color-text-inverted;
}

.News-next-label {
  color: $color-cta-primary;
}

.News-next-link {
  text-decoration: none !important;
  opacity: .7;
  display: inline-block;
  transition: opacity .3s;
  &:hover {
    opacity: 1;
  }
}

.News-next-title {
  margin: 0;
}
