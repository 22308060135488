.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background: color("orange-2");
  padding: 0 1.5rem;
  line-height: 1.2;
  letter-spacing: 0.02em;
  min-width: 200px;
  color: color("white") !important;
  text-decoration: none !important;
  font-size: calc(1rem - 2px);
  font-weight: 500;
  min-height: calc(#{$input-line-height * $input-font-size} + #{$input-padding-y * 2} + #{$input-height-border});
  border-radius: 50px;
  transition: background .3s;
  appearance: none;
  .desktop &:hover {
    background: color("orange-3");
  }
}

button.Button {
  appearance: none;
  border: none;
}

.Button--arrow {
  justify-content: space-between;
  &:after {
    content: '';
    width: 1rem;
    height: 1rem;
    margin-left: 2rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    @extend %light-arrow-bg;
    flex-shrink: 0;
  }
  .desktop &:after {
    transition: transform .3s;
  }
  .desktop &:hover:after {
    transform: translateX(4px);
  }
}

.Button--icon {
  min-width: auto;
  width: calc(#{$input-line-height * $input-font-size} + #{$input-padding-y * 2} + #{$input-height-border});
  position: relative;
  font-size: 0;
  &:after {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: 0;
    transform: translateX(-50%) translateY(-50%) !important;
  }
}

