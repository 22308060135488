.Share {
  display: flex;
  @include heading();
  @include heading-type("i");
  margin-bottom: 1rem;
  *:not(:last-child) {
    margin-right: 1rem;
  }
  h6 {
    opacity: .5;
    font-weight: 500;
    margin: 0;
  }
  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
