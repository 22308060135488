.EventNav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @include contentSpacer("margin-bottom");
}

.EventNav-cta {
  margin-left: auto;
  margin-bottom: 0;
  order: 2;
}

.EventNav-toggle {
  display: flex;
  align-items: center;
  appearance: none;
  border: 0;
  background: none;
  transition: color .3s;
  padding: 0;
  margin-bottom: 0;
  order: 1;
  .desktop &:hover {
    color: color("orange-3");
  }
}

.EventNav-toggleIcon {
  width: 2rem;
  height: 2rem;
  border: 1px solid color("orange-3");
  margin-left: 1rem;
  border-radius: 50%;
  background-size: rem(18px);
  background-repeat: no-repeat;
  background-position: center;
  $style : #{"fill: none; stroke: " + $color-text + "; stroke-width: 1.5;"};
  background-image: url(svg("filter", $style));
  transition: background-color .2s;

  .desktop .EventNav-toggle:hover & {
    background-color: color("orange-3");
  }

  .EventNav-toggle.is-open & {
    transform: rotate(45deg);
    background-size: rem(16px);
    $style : #{"fill: none; stroke: " + $color-text + "; stroke-width: 1.2;"};
    background-image: url(svg("plus", $style));
  }
}

.EventNav-filters {
  flex: 0 0 100%;
  order: 3;
}

.EventNav-filtersPanel {
  margin-top: 1rem;
  @include res-prop(("padding-top","padding-bottom"), $grid-gutter-widths);
  background-color: $color-background-gray;
}

.EventNav-filtersCol {
  @include res-prop("margin-top", $grid-gutter-widths);
  &:first-child {
    margin-top: 0;
  }
  &:before {
    display: none;
    content: '';
    position: absolute;
    @include res-prop("left", $grid-gutter-widths, -1);
    top: 0;
    bottom: 0;
    border-left: 1px solid rgba(#000, 0.2);
  }
  @include media-breakpoint-down(xs) {
    & + & {
      padding-top: 0;
    }
  }
  @include media-breakpoint-up(sm) {
    @include make-col(4);
    &:nth-child(-n+3) {
      margin-top: 0;
    }
    &:not(:nth-child(3n+1)) {
      &:before {
        display: block;
      }
    }
  }
  p {
    font-weight: 700;
    margin-bottom: 0.25rem;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  a {
    position: relative;
    padding-left: 20px;
    text-decoration: none;
    transition: color .2s;
    .desktop &:hover {
      color: $color-cta-primary;
    }
    @include media-breakpoint-down(xs) {
      padding-left: 16px;
    }
    &:before,
    &:after {
      position: absolute;
      content: '';
      width: 14px;
      height: 14px;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      border-radius: 50%;
      @include media-breakpoint-down(xs) {
        width: 12px;
        height: 12px;
      }
    }
    &:before {
      border: 1px solid currentColor;
      z-index: 2;
    }
  }
  a.is-active {
    &:after {
      border: 3px solid $color-background-gray;;
      background-color: $color-cta-primary;
    }
  }
  a.is-disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.EventNav-activeFilters {
  background-color: #fff; // needed for BlockUI
  order: 4;
  flex: 0 0 100%;
  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 1.25rem 0 0;
  }
  li {
    margin-right: 0.75rem;
    margin-top: 0.75rem;
  }
  a {
    display: flex;
    align-items: center;
    font-size: $small-font-size;
    line-height: $line-height-sm;
    border: 1px solid currentColor;
    border-radius: 10rem;
    padding: 0.2em 0.75em;
    text-decoration: none;
    transition: color .2s;
    .desktop &:hover {
      color: $color-cta-primary;
    }
    .Icon {
      stroke-width: 1.5px;
      flex-shrink: 0;
      margin-left: 0.5rem;
      transform: rotate(45deg) scale(0.75);
    }
  }
}
