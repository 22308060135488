.Overlay {
  position: relative;
  min-height: 100vh;
}

.Overlay-header {
  position: absolute;
  width: 100%;
  @include res-prop("top", $site-header-paddings);
  z-index: 3;
  .container {
    @include res-prop("height", $site-header-heights);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .inverted-header & {
    color: color("white");
  }
  .fancybox-content & {
    display: none;
  }
}

.Overlay-brand {
  font-size: 0;
}

.Overlay-logo {
  @extend .SiteHeader-logo;
}

.Overlay-back {
  // size the same as menu toggle
  width: 38/16 * 1rem;
  height: 38/16 * 1rem;
  @include media-breakpoint-down(xs) {
    width: 38/14 * 1rem;
    height: 38/14 * 1rem;
  }
  display: block;
  padding: 0;
  position: relative;
  background: none;
  &:before {
    content: '';
    position: absolute;
    transform: rotate(45deg);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-size: 48%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: $color-cta-primary;
    @extend %light-plus-bg;
  }
}

.Overlay-cover {
  @include res-prop("padding-top", $site-header-total-height);
  // @include res-prop("padding-bottom", $site-header-total-height);
  @include media-breakpoint-down(sm) {
    // padding-bottom: 0;
  }
  @include media-breakpoint-up(md) {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 50%;
    bottom: 0;
  }
  @media (min-width: 992px) and (min-height: 550px) {
    .fancybox-content & {
      position: fixed;
      padding-top: 0;
      .container {
        @include res-prop("padding-top", $site-header-total-height);
        @include res-prop("padding-bottom", $site-header-total-height);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.Overlay-content {
  @include media-breakpoint-up(md) {
    margin-left: 50%;
  }
}

.Overlay-contentCol {
  padding-top: 3rem;
  padding-bottom: 3rem;
  @include media-breakpoint-up(sm) {
    @include make-col(8);
    @include make-col-offset(2);
  }
  @include media-breakpoint-only(md) {
    @include make-col(10);
    @include make-col-offset(1);
  }
  @include media-breakpoint-up(md) {
    padding-top: 6.375rem; // site header heights
  }
}
