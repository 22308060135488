.TextIntro {
  @include section();
}

.TextIntro-mainCol {
  @include contentCol();
}


.TextIntro-titleCol {
  @include media-breakpoint-up(sm) {
    @include make-col(8);
  }
  @include media-breakpoint-up(md) {
    @include make-col(6,10);
  }
}

.TextIntro-ctaCol {
  @include media-breakpoint-up(sm) {
    @include make-col(5);
  }
  @include media-breakpoint-up(md) {
    @include make-col(4,10);
  }
}

.TextIntro-bodyCol {
  @include media-breakpoint-up(sm) {
    @include make-col(7);
  }
  @include media-breakpoint-up(md) {
    @include make-col(6,10);
  }
}


.TextIntro-title {
  color: $color-cta-primary;
  @include contentSpacer("margin-bottom");
}

.TextIntro-cta {
  @include contentSpacer("margin-bottom");
}
