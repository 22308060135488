.Instagram {
  @include section();
  overflow: hidden;
  .swiper-container {
    overflow: visible;
    @include make-row();
    // @include res-prop("margin-left", $grid-gutter-widths, -0.5);
    // @include res-prop("margin-right", $grid-gutter-widths, -0.5);
  }
  .swiper-slide {
    @include make-col-ready();
    @include make-col(5);
    @include media-breakpoint-up(sm) {
      @include make-col(4);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(3);
    }
  }
  .swiper-scrollbar {
    // width: auto !important;
    @include res-prop(("margin-left", "margin-right"), $grid-gutter-widths, 0.5);
  }
}

.Instagram-label {
  @include contentSpacer("margin-bottom");
}

.Instagram-col {
  @include contentCol();
}

.Gallery-imageWrapper {
  overflow: hidden;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: currentColor;
    opacity: .05;
  }
}

.Instagram-image {
  padding-bottom: 100%;
  background: center/cover no-repeat;
  @include lazyloaded();
}
