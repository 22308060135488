.SchoolEvents {
  @include sectionSpacer("margin-top");
  @include sectionSpacer("margin-bottom");
  position: relative;
}

.SchoolEvents-contentCol {
  @include contentCol();
  &:before {
    content: '';
    display: block;
    border-top: 1px solid currentColor;
    opacity: .2;
    @include contentSpacer("padding-bottom");
  }
}

.SchoolEvents-logoCol {
  @include media-breakpoint-down(xs) {
    @include contentSpacer("margin-bottom");
  }
  @include media-breakpoint-up(sm) {
    @include make-col(4);
  }
  @include media-breakpoint-up(md) {
    @include make-col(4,10);
  }
}

.SchoolEvents-logo {
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  max-height: 80px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 1rem;
  }

  @include media-breakpoint-up(sm) {
    max-height: 120px;
  }

  &:before {
    content: '';
    display: block;
    padding-bottom: 30%;
  }
}

.SchoolEvents-mainCol {
  @include media-breakpoint-up(sm) {
    @include make-col(8);
    margin-left: auto;
  }
  @include media-breakpoint-up(md) {
    @include make-col(6,10);
  }
}

.SchoolEvents-item {
  @include contentSpacer("margin-top");
  &:after {
    content: '';
    display: block;
    border-bottom: 1px solid currentColor;
    @include contentSpacer("padding-top");
    opacity: .2;
  }
}

.Accordion-toggle {
  @include contentSpacer("margin-top");
  @include contentSpacer("margin-bottom");
  .Accordion-item:first-child & {
    margin-top: 0;
  }
  cursor: pointer;
  display: flex;
  align-items: center;
  &:after {
    content: '';
    display: block;
    flex-shrink: 0;
    width: 2rem;
    height: 2rem;
    border: 1px solid color("orange-3");
    border-radius: 50%;
    margin-left: 1rem;
    background-size: 1rem;
    background-repeat: no-repeat;
    background-position: center;
    @extend %dark-plus-bg;
    transition: transform .3s, background-color .2s;
    @include if-dark-background {
      @extend %light-plus-bg;
    }
  }
  &:hover:after {
    background-color: color("orange-3");
  }
  &.is-open {
    &:after {
      transform: rotate(45deg);
    }
  }
}

.Accordion-title {
  font-weight: 600;
  margin: 0 auto 0 0;
}

.Accordion-content {
  .Prose {
    @include contentSpacer("padding-bottom");
  }
}
