.Roadmap {
  @include section();
  background-color: $color-background-blue-2;
  overflow: hidden;
  .swiper-container {
    overflow: visible;
  }
  .swiper-slide {
    width: auto;
    @include media-breakpoint-down(xs) {
      width: 80%;
      padding-top: 1.5rem;
      max-width: 400px;
      &:first-child {
        display: none;
      }
    }
  }
  .swiper-scrollbar {
    color: $color-text-inverted;
  }
}

.Roadmap-mainCol {
  @include contentCol();
}

.Roadmap-step {
  display: flex;
  width: 24rem;
  flex-direction: column;
  @include media-breakpoint-down(xs) {
    width: 100%;
  }
  // background-color: rgba(#fff,.1);
  // * {
  //   background-color: rgba(#fff,.1);
  // }
}

.Roadmap-step-figure {
  width: rem(260px);
  position: relative;
  margin-bottom: 2.5rem;
  margin-left: 2rem;
  &:before {
    content: '';
    display: block;
    padding-bottom: 10/16 * 100%;
  }
  @include media-breakpoint-down(xs) {
    order: 2;
    width: auto;
    margin-right: 3.5rem;
    margin-bottom: 0;
    margin-top: 1rem;
    .swiper-slide:nth-child(2) & {
      margin-left: 0.75rem;
    }
    &:not(.Roadmap-step-figure--hasImage) {
      display: none;
    }
  }
}

.Roadmap-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: center/cover no-repeat;
}

.Roadmap-step-video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-color: rgba($color-text, .15);
  }
  &:after {
    content: '';
    display: block;
    width: rem(56px);
    height: rem(56px);
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    transform: translate(-50%, -50%);
    $style : "fill: none; stroke: #fff; stroke-width: 1.2;";
    background-image: url(svg("play", $style));
    background-size: 100% 100%;
  }
}

.Roadmap-step-marker {
  color: $color-cta-primary;
  border-top: 1px solid currentColor;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    z-index: 2;
    left: 2rem;
    width: 1.5rem;
    height: 1.5rem;
    background-color: currentColor;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
  .swiper-slide:last-child() &:after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border: 2px solid $color-background-blue-2;
    right: 4px;
    top: -4px;
  }
  .is-highlight &:before {
    background-color: $color-text-inverted;
  }
  .Roadmap-step--first &:before {
    display: none;
  }
  @include media-breakpoint-down(xs) {
    .swiper-slide:nth-child(2) &:before {
      left: 0;
      transform: translate(0, -50%);
    }
  }
}

.Roadmap-step--first {
  padding-bottom: 3rem;
}

.Roadmap-step-cover {
  width: rem(200px);
  height: rem(200px);
  border: 1px solid $color-cta-primary;
  border-radius: 50%;
  position: absolute;
  transform: translateY(-50%);
  background-color: $color-background-blue-2;
  overflow: hidden;
}

.Roadmap-step-header {
  padding-top: rem(120px);
  width: rem(200px);
  text-align: center;
}

.Roadmap-step-header-title {
  color: $color-cta-primary;
  font-weight: 600;
  margin: 0;
}

.Roadmap-step-header-caption {
  @include heading();
  @include heading-type("i");
  color: $color-text-inverted;
  margin: .25rem 0 0;
}

.Roadmap-step-body {
  margin: -1.5rem 2rem 0 .5rem;
  padding: 3.5rem 1.5rem 1.5rem;
  position: relative;
  border-radius: 4px;
  .is-highlight & {
    background-color: $color-cta-primary;
  }
  @include media-breakpoint-down(xs) {
    .swiper-slide:nth-child(2) & {
      margin-left: -.75rem;
    }
  }
}

.Roadmap-step-title {
  @include heading();
  @include heading-type("i");
  color: $color-cta-primary;
  margin: 0;
  .is-highlight & {
    color: $color-text;
  }
}

.Roadmap-step-caption {
  @include heading();
  @include heading-type("i");
  margin: .5rem 0 0;
  color: $color-text-inverted;
}

.Roadmap-step-text {
  line-height: 1.4;
  color: $color-text-inverted;
  margin: 1rem 0 0;
}

.Roadmap-step-link {
  line-height: 1.4;
  margin: 1rem 0 0;
  .Roadmap-step-text + & {
    margin-top: 0.5rem;
  }
  a {
    color: $color-text-inverted;
    text-decoration: underline;
  }
}


.Roadmap-intro {
  display: flex;
  align-items: center;
  @include sectionSpacer("margin-bottom");
  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.Roadmap-intro-header {
  flex-grow: 1;
}

.Roadmap-intro-header-title {
  color: $color-cta-primary;
  margin: 0;
}

.Roadmap-intro-header-caption {
  @include heading();
  @include heading-type("i");
  color: $color-text-inverted;
  margin: .5rem 0 0;
}

.Roadmap-intro-cover {
  width: rem(60px);
  height: rem(60px);
  position: relative;
  z-index: 0;
  border: 1px solid $color-cta-primary;
  border-radius: 50%;
  background-color: $color-background-blue-2;
  overflow: hidden;
  flex-shrink: 0;
  margin-left: 2rem;
}
