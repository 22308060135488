.ArrowLink {
  display: inline-block;
  color: currentColor;
  text-decoration: none !important;
  transition: color .3s;
  padding: 0 3rem 0 0;
  text-align: left;
  &:after {
    content: ' ';
    display: inline-block;
    vertical-align: middle;
    width: 2rem;
    height: 2rem;
    border: 1px solid color("orange-3");
    border-radius: 50%;
    margin-left: -2rem;
    transform: translateX(3rem);
    position: relative;
    top: -0.1em;
    background-size: 1rem;
    background-repeat: no-repeat;
    background-position: center;
    @extend %dark-arrow-bg;
    transition: transform .3s, background-color .2s;
    @include if-dark-background {
      @extend %light-arrow-bg;
    }
  }
  &:hover {
    color: color("orange-3");
  }
  &:hover:after {
    background-color: color("orange-3");
    transform: translateX(calc(4px + 3rem));
  }
}

button.ArrowLink {
  appearance: none;
  background: none;
  border: none;
}

.ArrowLink--big {
  @include heading();
  @include heading-type("b");
  margin-bottom: 0;
  padding-top: .1em;
  padding-bottom: .1em;
  font-weight: 600;
  color: rgba($color-text, .7);
  &:hover {
    color: $color-text;
  }
  &:after {
    top: 0;
  }
  @include if-dark-background {
    color: rgba($color-text-inverted, .7);
    &:hover {
      color: $color-text-inverted;
    }
  }
}
