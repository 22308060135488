.Icon {
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  fill: none;
  stroke: currentColor;
  stroke-width: 1.3px;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.Icon-svg {
  width: 100%;
  height: 100%;
}

.Icon--facebook,
.Icon--twitter,
.Icon--instagram,
.Icon--youtube,
.Icon--email,
.Icon--pinterest,
.Icon--whatsapp,
.Icon--linkedin {
  width: rem(20px);
  height: rem(20px);
  fill: currentColor;
  stroke: none;
}

.Icon--clock {
  width: rem(13px);
  height: rem(13px);
}

.Icon--lens {
  width: rem(20px);
  height: rem(20px);
}

.Icon--plus {
  width: 1rem;
  height: 1rem;
}
