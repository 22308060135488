body.cke_editable {
  @extend .Prose;
  margin: 1rem;
  background: unset;
  color: unset;
  overflow: visible;
}

.cke_panel_container body {
  background-color: unset;
  color: unset;
  overflow: visible;
}
