.Search {
  @include res-prop("padding-top", $site-header-total-height);
  @include sectionSpacer("margin-top", 2);
  @include sectionSpacer("margin-bottom", 2);
  min-height: 40vh;
}

.Search-mainCol {
  @include contentCol();
}

.Search-title {
  @include sectionSpacer("margin-top", 1);
}

.Search-results {
  list-style: none;
  margin: 2rem 0 0 0;
  padding: 0;
  border-top: 1px solid rgba(#000, .3);
}

.Search-item {
  padding: 2rem 0;
  border-bottom: 1px solid rgba(#000, .3);
}

.Search-item-link {
  text-decoration: none !important;
}

.Search-item-title {
  margin: 0 0 .2rem;
  transition: color .2s;
  a:hover &,
  .hover & {
    color: $color-cta-primary;
  }
}

.Search-item-text {
  margin: 0;
}
