.SiteFooter {
  background-color: #ececec;
  @include sectionSpacer("padding-top");
  @include sectionSpacer("padding-bottom");
  ul {
    list-style: none;
  }
}

.SiteFooter-footerNav {
  font-size: 14px;
  margin-bottom: 1rem;
  ul {
    margin: 0;
    padding: 0;
  }
  @include media-breakpoint-up(sm) {
    @include make-col(6);
    margin-bottom: 0;
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    li:not(:last-child) {
      margin-right: 1em;
    }
  }
}

.SiteFooter-socialsNav {
  font-size: 14px;
  display: none;
  font-weight: 500;
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 0;
  }
  li:not(:first-child) {
    margin-left: 1em;
  }
  @include media-breakpoint-up(sm) {
    display: block;
    @include make-col(6);
  }
}

.SiteFooter-main {
  @include sectionSpacer("padding-top");
  @include sectionSpacer("padding-bottom");
  @include media-breakpoint-down(xs) {
    padding-top: 0;
    padding-bottom: 0;
  }
  @include media-breakpoint-up(lg) {
    @include make-col(10);
    @include make-col-offset(1);
  }
}

.SiteFooter-mainNav {
  display: none;
  ul {
    margin: 0;
    padding: 0;
  }
  li:not(:first-child) {
    margin-top: .3em !important;
  }
  .badge {
    @extend %reset-badge;
  }
  li {
    @include heading();
    @include heading-type("e");
    margin: 0;
    line-height: 1.2;
    font-weight: 600;
  }
  a {
    opacity: .3;
    transition: opacity .2s;
  }
  a:hover {
    text-decoration: none;
    opacity: 1;
  }
  @include make-col(6,10);
  @include media-breakpoint-up(lg) {
    display: block;
  }
}

.SiteFooter-aside {
  @include media-breakpoint-up(lg) {
    @include make-col(4,10);
  }
}

.SiteFooter-contact {
  @include media-breakpoint-up(sm) {
    @include make-col(8);
  }
  @include media-breakpoint-up(md) {
    @include make-col(5);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(12);
  }
}

.SiteFooter-contact-form {
  margin-bottom: 2rem;
  input::placeholder {
    color: $color-text;
  }
  .form-group {
    display: flex;
    margin-bottom: 0;
    button {
      flex-shrink: 0;
      margin-left: 1rem;
    }
  }
  .alert {
    margin-top: 1rem;
    display: none;
  }
  @include media-breakpoint-up(sm) {
    margin-bottom: 2rem;
  }
}

.SiteFooter-contact-prose {
  font-weight: 600;
  margin-bottom: 2rem;
  > *:last-child {
    margin-bottom: 0;
  }
  a:hover {
    text-decoration: underline !important;
  }
  @include media-breakpoint-only(md) {
    margin-bottom: 0;
  }
}

.SiteFooter-officies {
  display: none;
  ul {
    margin: 0;
    padding: 0;
    @include make-row();
  }
  li {
    @include make-col-ready();
    @include make-col(4);
    @include media-breakpoint-up(md) {
      @include make-col(6);
    }
  }
  h6 {
    text-transform: none;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    line-height: $line-height-base;
  }
  p {
    font-size: calc(1rem - 2px);
    margin: 0;
  }
  @include media-breakpoint-up(sm) {
    display: block;
  }
  @include media-breakpoint-up(md) {
    @include make-col(6);
    @include make-col-offset(1);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(12);
    @include make-col-offset(0);
  }
}

.SiteFooter-colophon {
  opacity: .5;
  font-size: calc(1rem - 2px);
  p {
    margin: 0;
  }
  @include media-breakpoint-up(sm) {
    @include make-col(6);
  }
}

.SiteFooter-credits {
  opacity: .5;
  font-size: calc(1rem - 2px);
  p {
    margin: 0;
  }
  @include media-breakpoint-up(sm) {
    @include make-col(6);
    text-align: right;
  }
}

.SiteFooter-newsletter {

}

.SiteFooter-newsletter-fields {

}
