.SiteHeader {
  @include res-prop(("padding-top", "padding-bottom"), $site-header-paddings);
  font-size: calc(1rem - 2px);
  font-weight: 600;
  transition: color .3s;
  position: relative;
  z-index: $z-index-header;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: $z-index-header-bg;
    transition: transform .3s; // cubic-bezier("easeOutQuart");
    transform: translate3D(0,-100%,0);
  }
  ul {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
  }
  li {
    white-space: nowrap;
    &:not(:first-child) {
      margin-left: 1rem;
    }
  }
  a {
    display: inline-block;
  }
  .mobile &,
  .tablet & {
    position: fixed;
    transition: transform .4s, color .3s;
    transform: translate3D(0,0,0);
  }
  .inverted-header & {
    color: color("white");
  }
  .show-search & {
    transition: transform .4s, color .3s .2s;
    color: color("white") !important;
  }
  .show-menu & {
    @include media-breakpoint-down(md) {
      transition: transform .4s, color .3s .2s;
      color: color("white") !important;
    }
  }
}

.SiteHeader--unpinned {
  .mobile &,
  .tablet & {
    transform: translate3D(0,-100%,0);
    pointer-events: none;
  }
}

html:not(.show-menu) .SiteHeader--pinned:not(.SiteHeader--top) {
  color: inherit;
}

.SiteHeader--pinned:not(.SiteHeader--top) {
  &:before {
    transform: translate3D(0,0,0);
  }
}

.SiteHeader-container {
  display: flex;
  align-items: center;
  position: relative;
  z-index: $z-index-header-container;
  @include res-prop("height", $site-header-heights);
}

.SiteHeader-brand {
  font-size: 0;
  margin-right: 2rem;
  text-decoration: none !important;
  @include media-breakpoint-down(xs) {
    margin-right: auto;
  }
}

.SiteHeader-logo {
  position: relative;
  display: inline-block;
  height: 0;
  padding-bottom: 32/140 * 100%;
  // width: 140px;
  width: 8rem;
  @include media-breakpoint-down(md) {
    // width: 120px;
  }
  svg {
    fill: currentColor;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.SiteHeader-menuToggle,
.SiteHeader-searchToggle {
  padding: 0;
  width: 38/16 * 1rem;
  height: 38/16 * 1rem;
  border-radius: 50%;
  overflow: hidden;
  @include media-breakpoint-down(xs) {
    width: 38/14 * 1rem;
    height: 38/14 * 1rem;
  }
  font-size: 0;
  position: relative;
  z-index: 1;
  color: inherit;
  cursor: pointer;
  appearance: none;
  border: none;
  background-color: transparent;
  &:before,
  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateZ(0);
    background-color: currentColor;
    transition: opacity .2s;
  }
  &:before {
    opacity: .3;
    z-index: 1;
    background-color: currentColor;
  }
  &:after {
    z-index: 2;
    background-color: #000;
    opacity: 0;
    .inverted-header & {
      background-color: #fff;
    }
    .show-menu {
      @include media-breakpoint-down(md) {
        background-color: #fff;
      }
    }
    .show-search {
      background-color: #fff;
    }
  }
  .desktop &:hover:after {
    opacity: .15;
  }
}

.SiteHeader-menuToggle-close,
.SiteHeader-searchToggle-close {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(45deg) scale(0);
  transform-origin: 50% 50%;
  transition: transform .3s;
  opacity: 0;
  [aria-pressed="true"] & {
    transform:  rotate(45deg) scale(1);
    opacity: 1;
  }
  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 48%;
    background-repeat: no-repeat;
    background-position: center;
  }
  &:after {
    @extend %dark-plus-bg;
    .inverted-header & {
      opacity: 0;
    }
    .show-menu &,
    .show-search & {
      opacity: 0;
    }
  }
  &:before {
    @extend %light-plus-bg;
    opacity: 0;
    .inverted-header & {
      opacity: 1;
    }
    .show-menu &,
    .show-search & {
      opacity: 1;
    }
  }
}

.SiteHeader-menuToggle {
  @include media-breakpoint-down(xs) {
    order: 10;
    margin-left: 1rem;
  }
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.SiteHeader-menuToggle-hamburger {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: 50% 50%;
  transition: transform .3s, opacity .3s;
  z-index: 4;
  .SiteHeader-menuToggle[aria-pressed="true"] & {
    transform: scale(0);
    opacity: 0;
  }
  svg {
    position: absolute;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line {
      stroke-width: 1px;
      stroke: currentColor;
    }
  }
}

.SiteHeader-searchToggle {
  margin-left: 1rem;
  @include media-breakpoint-down(xs) {
    margin-left: 0;
  }
  .Icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    transform-origin: 50% 50%;
    transition: transform .3s;
  }
  &[aria-pressed="true"] .Icon {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
  }
}

.SiteHeader-mainNav {
  > ul > li.is-active > a:not(.badge) {
    text-decoration: underline;
  }
  @include media-breakpoint-down(md) {
    display: none;
  }
  li.has-childs {
    position: relative;
    > a {
      pointer-events: none;
    }
    &:hover {
      ul {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
  ul ul {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: calc(100% + .5rem);
    flex-direction: column;
    left: 50%;
    transition: opacity .2s, transform .3s;
    transform: translateX(-50%);
    background-color: #fff;
    font-size: calc(1rem - 1px);
    font-weight: 400;
    color: $color-text;
    border-radius: .75rem;
    box-shadow: 0 -2px  8px rgba(#000, .15),
                0 -2px 16px rgba(#000, .15),
                0 -2px 32px rgba(#000, .05);
    &:after {
      content: '';
      position: absolute;
      height: .5rem;
      width: 100%;
      //background-color: #ff0;
      margin-top: -.5rem;
    }
    &:before {
      content: '';
      position: absolute;
      width: 1rem;
      height: 1rem;
      border: .5rem solid transparent;
      border-bottom-color: #fff;
      transform: translateY(-100%);
    }
  }
  li li {
    margin-left: 0 !important;
    width: 100%;
    text-align: center;
    overflow: hidden;
    &:first-child {
      border-radius: .75rem .75rem 0 0;
    }
    &:last-child {
      border-radius: 0 0 .75rem .75rem;
    }
    &.is-active a {
      font-weight: 600;
      text-decoration: none !important;
    }
  }
  li li a {
    font-weight: 400;
    display: block;
    padding: .75rem 1rem;
    transition: background-color .15s;
    &:hover {
      background-color: #eee;
      text-decoration: none;
    }
  }
}

.SiteHeader-serviceNav {
  margin-left: auto;
  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.SiteHeader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  z-index: $z-index-header-overlay;
  color: color("white");
  .show-menu & {
    pointer-events: auto;
  }
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.SiteHeader-overlay-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: color("orange-3");
  transform-origin: 0 0;
  transform: scaleY(0);
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    opacity: .05;
  }
}

.SiteHeader-overlay-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
   -webkit-overflow-scrolling: touch;
}

.SiteHeader-overlay-content {
  display: flex;
  flex-direction: column;
}

.SiteHeader-overlay-container {
  min-height: 100vh;
  padding-top: 6rem;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.SiteHeader-overlay-serviceNav {
  margin-bottom: 2rem;
  opacity: 0;
  @include media-breakpoint-up(sm) {
    display: none;
  }
  ul {
    display: flex;
    flex-wrap: nowrap;
  }
  li {
    flex: 1 1 50%;
    display: flex;
    a {
      flex-grow: 1;
      text-align: center;
      font-size: calc(1rem - 2px);
    }
  }
}

.SiteHeader-overlay-mainNav {
  margin-bottom: 2rem;
  opacity: 0;
  .badge {
    @extend %reset-badge;
  }
  ul {
    flex-direction: column;
    align-items: flex-start;
  }
  li {
    margin-left: 0 !important;
    @include heading();
    @include heading-type("e");
    font-weight: 600 !important;
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    &:hover {
      text-decoration: underline !important;
    }
  }
  li ul li {
    font-size: calc(1rem + 2px);
    padding-left: 2rem;
    &:first-child {
      margin-top: calc(.7rem + .3em);
    }
  }
  li.has-childs > a {
    display: flex;
    align-items: center;
    &:after {
      content: '';
      margin-left: .75em;
      $style : "fill: none; stroke: #fff; stroke-width: 2.2;";
      background-image: url(svg("plus", $style));
      background-size: 100%;
      width: 12px;
      height: 12px;
      transition: transform .3s;
      @include media-breakpoint-up(sm) {
        width: 1rem;
        height: 1rem;
        margin-left: .5em;
      }
    }
    &.is-open:after {
      transform: rotate(45deg);
    }
  }
  @include media-breakpoint-up(sm) {
    @include make-col(6);
    @include make-col-offset(1);
  }
}

.SiteHeader-overlay-officies {
  opacity: 0;
  font-size: 1rem;
  margin-bottom: 2rem;
  ul {
    flex-direction: column;
    align-items: flex-start;
  }
  li {
    margin-left: 0 !important;
    @include media-breakpoint-up(sm) {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  }
  h6 {
    font-size: calc(1rem + 2px);
    text-transform: none;
    line-height: inherit;
    margin: 0;
    font-weight: 600;
    @include media-breakpoint-down(xs) {
      cursor: pointer;
      display: flex;
      align-items: center;
      &:after {
        content: '';
        margin-left: .75em;
        $style : "fill: none; stroke: #fff; stroke-width: 2.2;";
        background-image: url(svg("plus", $style));
        background-size: 100%;
        width: 12px;
        height: 12px;
        transition: transform .3s;
      }
      &.is-open:after {
        transform: rotate(45deg);
      }
    }
  }
  p {
    font-weight: 400;
    margin-bottom: 0;
    @include media-breakpoint-down(xs) {
      &:after {
        content: '';
        display: block;
        height: 1rem;
      }
    }
    @include media-breakpoint-up(sm) {
      display: block !important;
      height: auto !important;
    }
  }
  @include media-breakpoint-up(sm) {
    @include make-col(4);
  }
}

.SiteHeader-overlay-socials {
  font-weight: 500;
  opacity: 0;
  font-size: 1rem;
  @include media-breakpoint-down(xs) {
    ul {
      flex-direction: column;
      align-items: flex-start;
    }
    li {
      margin-left: 0 !important;
    }
  }
  @include media-breakpoint-up(sm) {
    @include make-col(10);
    @include make-col-offset(1);
    margin-top: 5vh;
  }
}

.SiteHeader-search {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: $z-index-header-overlay;
  color: color("white");
  .show-search & {
    pointer-events: auto;
  }
}

.SiteHeader-search-mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(#000, .2);
  opacity: 0;
  transition: opacity .3s;
  .show-search & {
    opacity: 1;
  }
}

.SiteHeader-search-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.SiteHeader-search-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scaleY(0);
  transform-origin: 0 0;
  background-color: $color-background-blue-1;
}

.SiteHeader-search-content {
  position: relative;
  @include res-prop("padding-top", $site-header-total-height);
  @include sectionSpacer("margin-top", 2);
  @include sectionSpacer("margin-bottom", 2);
}

.SiteHeader-search-col {
  opacity: 0;
  @include contentCol();
}
