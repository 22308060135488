.Tag {
  display: inline-block;
  background-color: $color-background-blue-1;
  color: $color-text-inverted;
  font-size: calc(1rem - 4px);
  text-transform: uppercase;
  font-weight: 500;
  padding: .1em .5em 0 .5em;
  border-radius: 2px;
  @include media-breakpoint-down(xs) {
    font-size: calc(1rem - 2px);
  }
}

.Tag--outline {
  background-color: transparent;
  position: relative;
  color: $color-text;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid currentcolor;
    border-radius: 2px;
  }
}

.Tag--secondary:not(.Tag--outline) {
  background-color: $color-background-orange;
}

.Tag--outline.Tag--secondary {
  color: $color-background-orange;
}
