.PeopleList {
  @include section();
  overflow: hidden;
}

.PeopleList-main {
  @include contentCol();
}

.PeopleList-header {
  @include sectionSpacer("margin-bottom");
  @include media-breakpoint-up(sm) {
    @include make-col(9);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(6);
  }
}

.PeopleList-title {
  color: $color-cta-primary;
}

.PeopleList-itemList {
  @include res-prop(("margin-left", "margin-right"), $grid-gutter-widths, -1);
}

.PeopleList-item {
  @include res-prop(("padding-left", "padding-right"), $grid-gutter-widths);
  @include res-prop("margin-top", $grid-gutter-widths, 3);
  display: flex;
  flex-direction: column;
  text-align: center;
  &:nth-child(1) {
    margin-top: 0;
  }
  @include media-breakpoint-up(sm) {
    @include make-col(4);
    &:nth-child(2),
    &:nth-child(3) {
      margin-top: 0;
    }
  }
}

.PeopleList-item-link {
  &:hover {
    text-decoration: none;
  }
}

.PeopleList-item-title {
  color: $color-cta-primary;
  margin: 0;
}

.PeopleList-item-caption {
  margin-top: .5rem;
  margin-bottom: 0;
}

.PeopleList-item-footer {
  display: flex;
  border-top: 1px solid $color-cta-primary;
  padding-top: .25rem;
  margin-bottom: 1rem;
  order: -1;
  span {
    margin-right: auto;
  }
  a {
    margin-left: 1rem;
  }
}

.PeopleList-item-avatar {
  display: flex;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 1rem;
  position: relative;
  z-index: 0;
  &:before {
    content: '';
    display: block;
    height: 0;
    padding-bottom: 100%;
  }
  &:after {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: .15;
    background-color: currentColor;
  }
}

.PeopleList-item-image {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: center/cover no-repeat;
  @include lazyloadedZoom();
}
